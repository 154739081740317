////////////////////////
// Bootstrap Overrides
////////////////////////

// Give the main nav more vertical padding
$navbar-padding-y: 1.5rem;

// The colour of the divider for the main menu dropdown
$dropdown-divider-bg: #ffffff;

////////////////////////
// Colours
////////////////////////

$primary: rgb(86, 93, 79);
$red: #AF2413;
$grey-dark: #111111;
$green-light: #7a9263;
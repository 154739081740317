
////////////////////////
// FONTS
////////////////////////

.rs {
  font-family: 'royal_signagerg' !important;   
}

////////////////////////
// Typography
////////////////////////

h1, h2 {
  font-family: 'royal_signagerg' !important;
  text-transform: lowercase;
}

h3 {
  font-weight: bold;
}

.text-muted {
  color: lighten($primary, 20%) !important;
}

////////////////////////
// Colours
////////////////////////

.bg-dark {
  background-color: $grey-dark !important;
}

////////////////////////
// Images
////////////////////////

img {
  max-width: 100%;
}

////////////////////////
// Nav
////////////////////////

.navbar {
  font-size: 1.2rem;
}

.navbar .navbar-brand {
  text-transform: lowercase;
}

div.dropdown-menu.show {
  background-color: #222; // for drop down menu color
}

.navbar-dark .navbar-nav .nav-link,
a.dropdown-item 
{
  color:rgba(255, 255, 255, 0.75);   // for font color of individual drop down item
  padding: auto 2rem;
  font-size: 1.25rem;
}


a.dropdown-item:hover {
  background-color: #000;   // for font color of individual drop down item
  color: white;
}

.cart-item-count {
  position: absolute !important;
  top: 0;
  left: 0;
  margin-top: 7px;
  margin-left: 37px;
  font-size: 0.75rem;
}

// Hide Shop Now button when on shop page and fix styles
.isolated-cart {
  a:first-child {
    display: none;
  }

  a:last-child {
    border-color: #222;
    border-radius: 100px !important;
  }
}

// Show nav above sticky buddi menu
.nav-item.show.dropdown {
  z-index: 100000;
}

////////////////////////
// Hero
////////////////////////

/* Background */

#background-video {
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  margin-top: 88px;
  position: fixed;
  background: $grey-dark;
}

#background-video video::-webkit-media-controls-overlay-play-button {
  display: none;
}

#background-video video {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
  position: fixed;
  z-index: -1;
}

#hero-container {
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



#hero-logo {
  max-width: 540px;
}

#hero-logo img {
  display: block;
  max-width: 90%;
  margin: auto;
  filter: drop-shadow(0px 0px 8px #000000);
}

////////////////////////
// Footer
////////////////////////

.footer {
  border-top: 1px solid rgba(0,0,0,0.15);
  text-align: center;

  p {
    color: $primary;
  }
}

////////////////////////
// Main Content Pages
////////////////////////

.content {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
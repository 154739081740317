////////////////////
// 
// Buddi custom stylesheet overrides
// Many styles will change. No specific selectors for Buddi sections. Need to hack.
// 
////////////////////

////////////////////
// Hide extra Buddi content
////////////////////

// The main navigation with a login, logout and cart buttons.
// #buddi-online-menu-nav,
// Bottom Footer Branding 
.bdi-py-5.bdi-bg-white,
// Sign Up - ©2022 Clearleaf Inc. All rights reserved.
.bdi-text-center.bdi-text-gray-500.bdi-text-xs
{
  display: none;
}

// Cart button color
body .buddi-online-menu .bdi-bg-red-600 {
  background-color: $green-light !important;
}

// 
#bdi-nav-button a:hover {
  color: white !important;
}

body .buddi-online-menu .bdi-product-card .bdi-bg-green-100 {
  background-color: #cc9562 !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

////////////////////
// Typography
////////////////////

body .buddi-online-menu {
  h2.bdi-text-gray-900 {
    text-transform: lowercase;
    color: $grey-dark;
  }

  // Product Page Product Title
  #swiper-wrapper + div h2 {
    color: $grey-dark !important;
    font-size: 2.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bdi-text-2xl {
    line-height: 2.5rem!important;
  }

}



////////////////////////////////////
// Swiper Hero Banner and Categories
////////////////////////////////////

.buddi-online-menu .swiper-button-next,
.buddi-online-menu .swiper-button-prev,
.buddi-online-menu .swiper-pagination {
  display: none;
}

////////////////////
// Link colors
////////////////////
body .buddi-online-menu {
  
  // Links
  .bdi-text-blue-500,
  .bdi-text-blue-600,
  .swiper-button-next:after,
  .swiper-button-prev:after
  {
    color: $green-light !important;

    &:hover {
      color: darken($green-light, 20%) !important;
    }
  }
  
  // Buttons
  form button.bdi-bg-green-500 {
    background-color: $green-light !important;

    &:hover {
      background-color: darken($green-light, 5%) !important;
    }
  }

  // Red
  ////////////////////
  
  // Reset filter link
  .bdi-text-red-600 {
    color: $red !important;
  }
  // On Sale button in menu filter
  button.bdi-bg-red-600 {
    background-color: $red !important;
  }

  // Pills and various light grey backgrounds
  .bdi-bg-gray-200 {
    background-color: lighten($primary, 60%) !important;
  }

  .bdi-text-gray-900 {
    color: $primary !important;
  }

  // Product Name on Card
  .product-card .bdi-text-xl {
    font-family: 'royal_signagerg' !important;
    text-transform: lowercase;
    color: $grey-dark !important;
  }

  // Sign Up form SKIP Button
  button.bdi-text-blue-700.bdi-border-blue-500 {
    border-color: $green-light !important;
    color: $green-light !important;

    &:hover {
      background-color: $green-light !important;
      color: white !important;
    }
  }

  // Swiper
  .swiper-button-next .swiper-button-blue,
  .swiper-button-prev .swiper-button-blue {
    color: #486453 !important;
  }

}

////////////////////
// Loading Spinners
////////////////////

// Product menu
// .bmo-icon-container svg,
// Initial menu load
#buddi-notification svg {
  height: 35px;
  margin: 2rem 0 !important;
}

////////////////////
// Product Image Carousel Dots
////////////////////

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active  {
  background-color: $green-light !important;
}
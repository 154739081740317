.AgeVerification {  
  img {
    max-width: 4rem !important;
    margin-bottom: 2rem;
    display: inline-block;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 42em;
  }

  .modal-content {
    background-color: $grey-dark;
    background-size: 700px;
    background-image: url("./../../images/cannabis-leaves-tiled.png");
  }
}
////////////////////////
// Imports
////////////////////////

@font-face {
  font-family: 'royal_signagerg';
  src: url('./fonts/royalsignage-rg-webfont.woff2') format('woff2'),
       url('./fonts/royalsignage-rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import "./styles/_variables.scss";
@import "~bootstrap/scss/bootstrap";
@import './components/AgeVerification/AgeVerification.scss';
@import "./styles/buddi.scss";
@import "./styles/custom.scss";
// @import "./styles/not-found.scss";